import template from './WorkbookView.html';

/**
 * Workbooks View page view model
 */
class WorkbooksViewPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.workbook_id = ko.observable();
		this.worksheet_id = ko.observable();
		this.records = ko.observableArray([]);
		this.worksheets = ko.observableArray([]);
		this.worksheet = ko.observable();
		this.worksheet_status = ko.observable('Unknown');
		this.workbook = ko.observable();

		this.selected_mapping_type = ko.observable('heading-is-names');
		this.available_maps = ko.observableArray([]);
		this.selected_map = ko.observable();
		this.available_pipelines = ko.observableArray();
		this.selected_pipeline = ko.observable();
		this.ui_params = ko.observableArray([]);
		this.ui_param_values = ko.observable({});
		this.pipeline_steps = ko.observableArray([]);

		this.selected_pipeline.subscribe((pipeline) => {
			let ui_params = [];
			pipeline.transformers.forEach((trans) => {
				if (trans.ui_param !== null)
				{
					ui_params.push(...trans.ui_param);
				}
			});
			this.ui_params(ui_params);

			let steps = [];
			pipeline.transformers.forEach((trans) => {
				if (trans.active) {
					trans.selected = true;
					steps.push(trans);
				}
			});
			this.pipeline_steps(steps);
		});

		this.worksheet_id.subscribe(() => {
			this.update_sheet();
		});
		
		this.initialize_grid();
	}

	initialize_grid()
	{
		let input_element = document.body.getElementsByClassName('data-cells')[0];
		this.grid = document.createElement('canvas-datagrid');
		this.grid.style.height = '60vh';
		this.grid.style.width = '100%';
		input_element.appendChild(this.grid);
	}

	async btnProcess2Batch_click ()
	{
		this.loading(true);

		let pipeline_steps = this.pipeline_steps();
		let pipeline_step_ids = [];
		pipeline_steps.forEach((step) => {
			if (step.selected)
				pipeline_step_ids.push(step.pipeline_step_id);
		});
		
		let params = {};
 		for (let [name, $input] of Object.entries(this.ui_param_values()))
 			params[name] = ($input)();
		params.pipeline_step_ids = pipeline_step_ids;

		const result = await Grape.fetches.postJSON('/api/etl/worksheet2batch', {
			worksheet_id: this.worksheet_id(),
			mapping_strategy: this.selected_mapping_type(),
			type: this.selected_pipeline().name,
			options: params
		});
		if (result.status == 'OK')
		{
			Grape.alerts.alert({type: 'success', message: 'Records has been created successfuly', title: 'Success'});
			this.update_sheets();
		}
		else
		{
			Grape.alerts.alert({type: 'error', message: result.message, title: 'Error'});
		}
		this.loading(false);
	}

	async btnProcessBatch_click()
	{
		const result = await Grape.fetches.postJSON('/api/etl/batch/start-process', {batch_id: this.worksheet().batch_id});
		this.update_sheets();
	}
	
	async update_lookups()
	{
		this.available_pipelines(await Grape.cache.get('PipelineLookup'));
	}

	/**
	 * Update list of sheets from server
	 */
	async update_sheets()
	{
		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			filter: [{field: 'workbook_id', value: this.workbook_id(), operator: '='}],
			fields: ['name', 'worksheet_id']
		});

		if (result.status == 'OK')
		{
			this.worksheets(result.records || []);
		}
		else
		{
			this.worksheets([]);
			throw new Error(result.message || result.code);
		}

	}

	/**
	 * Load worksheet and cells for the worksheet id in this.worksheet_id()
	 */
	async update_sheet()
	{
		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			filter: [{field: 'worksheet_id', value: this.worksheet_id(), operator: '='}]
		});

		if (result.status == 'OK')
		{
			this.worksheet(result.records[0]);
			await this.update_cells();

			if (!this.worksheet().batch_state)
			{
				if (this.workbook().sched_te)
					this.worksheet_status('Unbatched');
				else
					this.worksheet_status('Importing');
			}
			else if (this.worksheet().batch_state == 'ReadyForProcessing')
			{
				this.worksheet_status('ReadyForProcessing');
			}
			else
			{
				this.worksheet_status(this.worksheet().batch_state);
			}
		}
		else {
			throw new Error(result.message || result.code);
		}
	}


	async update_cells()
	{
		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_cell_value_array',
			schema: 'sheets',
			filter: [{field: 'worksheet_id', value: this.worksheet_id(), operator: '='}]
		});

		if (result.status == 'OK')
		{
			this.grid.data = result.records[0].data;
		}
		else {
			this.records([]);
			throw new Error(result.message || result.code);
		}

	}

	async btn_create_entity_click () 
	{
		Grape.navigate('entities-ui/entity-create');
	}

	row_view_entity_click (row)
	{
		Grape.navigate(`entities-ui/entity-view/${row.entity_id}`, {entity_id: JSON.stringify(row.entity_id)});
	}
}

/**
* Workbooks list page class
*/
class WorkbooksViewPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new WorkbooksViewPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'WorkbooksViewPageClass';

		this.viewModel.workbook_id(bindings.workbook_id);
	}

	async init ()
	{
		document.title = 'Workbook';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_workbooks',
			schema: 'sheets',
			filter: [{field: 'workbook_id', value: this.viewModel.workbook_id(), operand: '='}]
		});

		if (result.status == 'OK' && result.records.length == 1)
		{
			this.viewModel.workbook(result.records[0]);
		}
		else {
			console.log(result);
			throw new Error(result.message || result.code);
		}

		await this.viewModel.update_lookups();
		await this.viewModel.update_sheets();

		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/workbook-view',
	page_class: WorkbooksViewPageClass,
	template: template
};
