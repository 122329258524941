
import PipelineEdit from './PipelineEdit/PipelineEdit.js';
import WorkbookList from './WorkbookList/WorkbookList.js';
import WorksheetList from './WorksheetList/WorksheetList.js';
import WorkbookView from './WorkbookView/WorkbookView.js';
import RecordList from './records/RecordList.js';
import BatchList from './batches/BatchListPage.js';

export default [
	PipelineEdit,
	RecordList,
	BatchList,
	WorkbookList,
	WorksheetList,
	WorkbookView
];
