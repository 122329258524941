import template from './BatchListPage.html';
import BatchListModel from './BatchListModel.js';

/**
 * Batch List page view model
 */
class BatchListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.model = new BatchListModel();
	}

}

/**
* Workbooks list page class
*/
class BatchListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new BatchListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'BatchListPageClass';
	}

	async init ()
	{
		document.title = 'ETL Batches';
	}

	async updateData ()
	{
		this.viewModel.loading(true);
		this.viewModel.model.load();
		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/batch-list',
	page_class: BatchListPageClass,
	template: template
};
