
import RecordModel from './RecordModel.js';

class RecordListModel
{
	constructor(batch_id)
	{
		this.batch_id = ko.observable(batch_id);
		this.records = ko.observableArray();
	}

	async load()
	{
		const result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_batch_records',
			schema: 'etl',
			limit: 1000,
			filter: [{field: 'batch_id', operator: '=', value: this.batch_id()}]
		});

		if (result.status != 'OK')
		{
			this.records([]);
			throw new Error(result.message || result.code);
		}

		const records = [];
		for (let r of result.records)
		{
			const record = new RecordModel(r);
			records.push(record);
		}
		records.sort((a,b) => a.seq() - b.seq());
		this.records(records);
	}
}

export default RecordListModel;

