import template from './WorksheetList.html';

/**
 * Worksheets List page view model
 */
class WorksheetsListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
	}

	async btnUpload_click ()
	{
		let input_element = document.body.getElementsByClassName('file-upload-input')[0];
		let form_data = new FormData();
		for (let file of input_element.files)
			form_data.append('file', file);
		let response = await fetch('/api/sheets/workbook-upload', {method: 'POST', body: form_data});
		let data = await response.text();
		this.page.updateData();
	}

	async btn_create_entity_click () 
	{
		Grape.navigate('entities-ui/entity-create');
	}

	row_view_entity_click (row)
	{
		Grape.navigate(`entities-ui/entity-view/${row.entity_id}`, {entity_id: JSON.stringify(row.entity_id)});
	}
}

/**
* Worksheets list page class
*/
class WorksheetsListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new WorksheetsListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'WorksheetsListPageClass';
	}

	async init ()
	{
		document.title = 'All Worksheets';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_worksheets',
			schema: 'sheets',
			sortfield: 'date_inserted',
			sortorder: 'DESC',
			limit: 100
		});

		if (result.status == 'OK')
			this.viewModel.records(result.records || []);
		else {
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}

		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/worksheet-list',
	page_class: WorksheetsListPageClass,
	template: template
};

