/**
 * This is an example of a GrapeUIPlugin that adds functionality to the front-end
 * @kind plugin
 * @class SamplePlugin
 */
class SamplePlugin {
	constructor(Grape, options) {
		this.Grape = Grape;
		this.options = options;
	}

}

export default SamplePlugin;
