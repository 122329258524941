
import RecordListModel from './RecordListModel.js';

class BatchModel
{
	constructor(obj = {})
	{
		this.batch_id = ko.observable();
		this.name = ko.observable();
		this.state = ko.observable();
		this.pipeline = ko.observable();
		this.processed_count = ko.observable();
		this.row_count = ko.observable();
		this.error_count = ko.observable();
		this.process_schedule_id = ko.observable();
		this.schedule_status = ko.observable();
		this.schedule_ts = ko.observable();
		this.schedule_te = ko.observable();

		this.set(obj);

		this.records = new RecordListModel(this.batch_id());
	}

	/**
	 * Load batch data
	 */
	async load()
	{
		if (!this.batch_id())
			throw new Error('batch id empty');
		const result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_batches',
			schema: 'etl',
			filter: [{field: 'batch_id', operator: '=', value: this.batch_id()}]
		});

		if (result.status != 'OK' || result.records.length != 1)
			throw new Error(result.message || result.code);

		this.set(result.records[0]);
	}

	/**
	 * Set from object
	 */
	set(obj)
	{
		if (obj.hasOwnProperty('batch_id'))
			this.batch_id(obj.batch_id || null);
		if (obj.hasOwnProperty('name'))
			this.name(obj.name || null);
		if (obj.hasOwnProperty('state'))
			this.state(obj.state || null);
		if (obj.hasOwnProperty('pipeline'))
			this.pipeline(obj.pipeline || null);
		if (obj.hasOwnProperty('processed_count'))
			this.processed_count(obj.processed_count || null);
		if (obj.hasOwnProperty('row_count'))
			this.row_count(obj.row_count || null);
		if (obj.hasOwnProperty('error_count'))
			this.error_count(obj.error_count || null);
		if (obj.hasOwnProperty('process_schedule_id'))
			this.process_schedule_id(obj.process_schedule_id || null);
		if (obj.hasOwnProperty('schedule_status'))
			this.schedule_status(obj.schedule_status || null);
		if (obj.hasOwnProperty('schedule_ts'))
			this.schedule_ts(obj.schedule_ts || null);
		if (obj.hasOwnProperty('schedule_te'))
			this.schedule_te(obj.schedule_te || null);
	}

	/**
	 * Perform API call to start batch processing
	 */
	async start_process()
	{
		// TODO check state
		const result = await Grape.fetches.postJSON('/api/etl/batch/start-process', {batch_id: this.batch_id()});
		return result;
	}
}

export default BatchModel;

