/**
 * The PipelineEdit page displays a list of all ETL pipelines, and Batch Types associated with them. It should include functions for editing and viewing everything related.
 */

import template from './PipelineEdit.html';

/**
 * PipelineEdit Page view model
 */
class PipelineEditPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
	}

	async update() {
	}
}

/**
 * PipelineEdit page class
 */
class PipelineEditPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new PipelineEditPageViewModel(this);
		this.name = 'PipelineEditPageClass';
	}

	async init() {
	}

	async updateData() {
		await this.viewModel.update();
	}

	async teardown() {
	}
}

export default {
	route: '[/]ui/etl/pipeline-edit',
	page_class: PipelineEditPageClass,
	template: template
}
