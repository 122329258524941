
import BatchModel from './BatchModel.js';

class BatchListModel
{
	constructor()
	{
		this.batches = ko.observableArray();
	}

	// Load list of batches
	async load()
	{
		const result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_batches',
			schema: 'etl',
			sortfield: 'batch_id',
			sortorder: 'DESC',
			limit: 100
		});

		if (result.status != 'OK')
		{
			this.batches([]);
			throw new Error(result.message || result.code);
		}

		const batches = [];
		for (let r of result.records)
		{
			const batch = new BatchModel(r);
			batches.push(batch);
		}
		this.batches(batches);
	}
}

export default BatchListModel;

