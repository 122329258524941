import template from './RecordList.html';
import BatchModel from '../batches/BatchModel.js';

/**
 * Records List page view model
 */
class RecordsListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
		this.batch = new BatchModel({batch_id: page.bindings.batch_id});
	}

	row_view_entity_click (row)
	{
		Grape.navigate(`etl-ui/workbook-view?workbook_id=${row.workbook_id}`);
	}

	async btnProcess_click()
	{
		this.loading(true);
		const result = await Grape.fetches.postJSON('/api/etl/batch/start-process', {batch_id: this.batch.batch_id()});
		console.log(result);
		await this.batch.load();
		this.loading(false);
	}
}

/**
* Records list page class
*/
class RecordsListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new RecordsListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'RecordsListPageClass';
	}

	async init ()
	{
		document.title = 'All Records';
	}

	async updateData ()
	{
		if (!this.bindings.batch_id)
			return;
		this.viewModel.loading(true);
		await this.viewModel.batch.load();
		await this.viewModel.batch.records.load();
		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/records-list',
	page_class: RecordsListPageClass,
	template: template
};

