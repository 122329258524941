

/**
 * This is an example of how to get the list of values inside a Grape lookup named PersonTitleLookup
 * (which gets created from initial.manifest and is defined in backend/db/data/sample_lookup.sql)
 * and register it into a Grape Cache called PersonTitleLookup
 * @kind cache
 * @class PersonTitleLookup
 * @description Custom lookup cache for PersonTitleLookup
 * @usage Grape.cache.fetch('PersonTitleLookup', function(data) { });
 */
export default {
	name: 'PipelineLookup',
	options: {
		table: 'v_pipelines',
		schema: 'etl',
		sortfield: 'name'
	}
};
