
class RecordModel
{
	constructor(obj = {})
	{
		this.record_id = ko.observable(obj.record_id || null);
		this.batch_id = ko.observable(obj.batch_id || null);
		this.seq = ko.observable(obj.seq || null);
		this.pipeline = ko.observable(obj.pipeline || null);
		this.batch_state = ko.observable(obj.batch_state || null);
		this.state = ko.observable(obj.record_state || null);
		this.extracted_data = ko.observable(obj.extracted_data || null);
		this.transformed_data = ko.observable(obj.transformed_data || null);

		this.set(obj);
	}

	/**
	 * Set from object
	 */
	set(obj)
	{
		if (obj.hasOwnProperty('record_id'))
			this.record_id(obj.record_id || null);
		if (obj.hasOwnProperty('batch_id'))
			this.batch_id(obj.batch_id || null);
		if (obj.hasOwnProperty('seq'))
			this.seq(obj.seq || null);
		if (obj.hasOwnProperty('pipeline'))
			this.pipeline(obj.pipeline || null);
		if (obj.hasOwnProperty('batch_state'))
			this.batch_state(obj.batch_state || null);
		if (obj.hasOwnProperty('record_state'))
			this.state(obj.record_state || null);
		if (obj.hasOwnProperty('extracted_data'))
			this.extracted_data(obj.extracted_data || null);
		if (obj.hasOwnProperty('transformed_data'))
			this.transformed_data(obj.transformed_data || null);
	}

}

export default RecordModel;

